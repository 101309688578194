
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS } from '@/modules/price-history/rates-price-history-common.service';
import { DocumentSettings } from '../../types';

@Component
export default class RatesPriceHistoryFilters extends Vue {
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;

    @Prop({ default: false })
    skeleton!: boolean;

    get settings() {
        const { documentSettings, ratesSettings, compset } = this.ratesPriceHistoryCommonService;

        const { los, pos } = documentSettings || {};
        const compsetType = compset && this.$tc(`compset.${compset.type}`);
        const compSet = compset ? `${compset.name} (${compsetType})` : '';
        const {
            provider, roomTypeId, mealTypeId, numberOfGuests, priceType,
        } = ratesSettings?.main || {};

        return {
            los,
            pos,
            compSet,
            provider,
            roomTypeId,
            mealTypeId,
            numberOfGuests,
            priceType,
        } as DocumentSettings;
    }

    get month() {
        return this.ratesPriceHistoryCommonService.documentSettings?.month;
    }

    get roomType() {
        const { roomTypeId } = this.settings;
        const { rooms } = this.roomTypesService;
        const currentRoom = rooms.find(room => room.id === roomTypeId);

        if (!currentRoom) {
            return '';
        }

        return currentRoom.name;
    }

    get mealType() {
        const { mealTypeId } = this.settings;
        const { mealTypes } = this.mealTypesService;
        const currentMealType = mealTypes.find(mealType => mealType.id === mealTypeId);

        if (!currentMealType) {
            return '';
        }

        return currentMealType.displayName;
    }

    getProviderLabel(providerName: string) {
        return this.providersService.getProviderLabel(providerName);
    }
}
