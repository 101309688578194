var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalWrapper',{class:{
        'rates-price-history-popup': true,
        'rates-price-history-popup--json': _vm.showRawData,
    },attrs:{"options":_vm.modalOptions},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('RatesPriceHistoryHeader')]},proxy:true},(!_vm.showRawData)?{key:"main",fn:function(){return [_c('div',{staticClass:"rates-price-history-popup__measure-container"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"main__header"},[_c('RatesPriceHistoryFilters',{attrs:{"isCluster":_vm.isCluster}})],1),(_vm.isAnalysisPage)?_c('div',{staticClass:"comparison-filter"},[_c('span',[_vm._v(_vm._s(_vm.$t(`rates.comparisonTo`)))]),_c('span',{staticClass:"comparison-filter__selected",domProps:{"textContent":_vm._s(_vm.compareLabel)}})]):_vm._e(),(!_vm.isNoChart)?_c('div',{staticClass:"main__graph",class:{
                        'main__graph--empty': _vm.isNoData,
                        'skeleton-container': _vm.isTrendsLoading || _vm.isDocumentLoading,
                    }},[(_vm.isNoData && !_vm.isTrendsLoading && !_vm.isDocumentLoading)?_c('div',{staticClass:"no-data-msg"},[_vm._v(" "+_vm._s(_vm.$t('rates.noRatesWereDetected'))+" ")]):_vm._e(),(_vm.chartData)?_c('CustomGraph',{class:{ skeleton: _vm.isTrendsLoading || _vm.isDocumentLoading },attrs:{"type":'line',"isTooltip":!!_vm.chartData.labels?.length,"haveFixedTooltip":true,"options":_vm.options,"chartData":_vm.chartData},on:{"current-day":_vm.setTooltipDay,"setDay":_vm.setDay,"tooltip-state-changed":_vm.setTooltipElement},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('div',{staticClass:"price-history-tooltip"})]},proxy:true}],null,false,1274193594)}):_c('div',{staticClass:"graph-placeholder",class:{ skeleton: _vm.isTrendsLoading || _vm.isDocumentLoading }}),(!_vm.isTrendsLoading && !_vm.isDocumentLoading)?_c('p',{staticClass:"last-scan"},[_vm._v(" "+_vm._s(_vm.$t('lastUpdate'))+" ")]):_vm._e()],1):_vm._e(),_c('RatesPriceHistoryTooltip',{attrs:{"focusElement":_vm.tooltipFocusElement,"day":_vm.tooltipDay,"ignoreHotels":_vm.hiddenGraphs,"priceShown":_vm.localPriceShown}}),_c('div',{class:{
                        'main__table-measure-container': true,
                        'content-wrapper': true,
                    }},[_c('div',{staticClass:"main__table"},[(_vm.isAllChannelsMode)?_c('RatesPriceHistoryTableAll',{attrs:{"hiddenGraphs":_vm.hiddenGraphs,"priceShown":_vm.localPriceShown,"tableDay":_vm.tableDay,"isLoading":_vm.isDocumentLoading,"ratesSettings":_vm.ratesSettings.main},on:{"toggle-hotel":_vm.toggleGraph}}):_c('RatesPriceHistoryTable',{attrs:{"hiddenGraphs":_vm.hiddenGraphs,"isLoading":_vm.isDocumentLoading},on:{"toggle-hotel":_vm.toggleGraph}})],1)])],1)]),_c('div',{staticClass:"rates-price-history-popup__sidebar"},[_c('PopupEventsContainer',{attrs:{"day":+_vm.day}}),_c('PriceHistoryStatistics',_vm._b({},'PriceHistoryStatistics',{
                documents: _vm.documents,
                tableDay: _vm.tableDay,
                priceShown: _vm.localPriceShown,
                skeleton: _vm.isTrendsLoading || _vm.isDocumentLoading,
                chartData: _vm.chartData,
            },false))],1)]},proxy:true}:{key:"main",fn:function(){return [_c('div',{staticClass:"main main--json"},[_c('JsonViewer',{attrs:{"value":_vm.documents?.main || {},"day":_vm.day}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer"},[(_vm.isSuperadmin)?_c('a',{on:{"click":function($event){_vm.showRawData = !_vm.showRawData}}},[_vm._v(" "+_vm._s(_vm.showRawData ? _vm.$t('popup.showNormal') : _vm.$t('popup.showRaw'))+" ")]):_vm._e(),_c('TogglePrices',{model:{value:(_vm.localPriceShown),callback:function ($$v) {_vm.localPriceShown=$$v},expression:"localPriceShown"}})],1)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }