
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ScreenshotLink extends Vue {
    @Prop({ type: String })
    screenshot!: string | null;

    get to() {
        return {
            name: `${this.$route.name!}.screenshot`,
            query: {
                url: this.screenshot!,
            },
        };
    }
}
