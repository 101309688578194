
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import RatesService, { RatesServiceS } from '../../rates/rates.service';
import HotelRatesDayPopup from '../components/hotel-rates-day.popup.vue';
import HotelAllChannelsDayPopup from '../components/hotel-all-channels-day.popup.vue';
import HotelAnalysisDayPopup from '../components/hotel-analysis-day.popup.vue';
import HotelCheapestDayPopup from '../components/hotel-cheapest-day.popup.vue';

@Component({
    components: {
        HotelRatesDayPopup,
        HotelAllChannelsDayPopup,
        HotelAnalysisDayPopup,
        HotelCheapestDayPopup,
    },
})
export default class HotelRatesDayModal extends Vue {
    @inject(RatesServiceS) private ratesService!: RatesService;

    @Prop({ type: Number })
    day!: Day;

    get isAnalysisMode() {
        return this.$route.path.includes('analysis');
    }

    get isAllChannelsMode() {
        return this.ratesService.settings.provider === 'all';
    }

    get isCheapestMode() {
        return this.ratesService.settings.provider === 'cheapest';
    }
}
