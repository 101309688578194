
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CommonDayPopup from './common-day-popup/index.vue';
import RatesService, { RatesServiceS } from '../../rates/rates.service';
import RatesDocumentModel from '../../rates/models/rates-document.model';
import RatesDocumentAllModel from '../../rates/models/rates-document-all.model';

@Component({
    components: { CommonDayPopup },
})
export default class HotelRatesDayPopup extends Vue {
    /** This component is only for regular hotel rates page popup */

    @inject(RatesServiceS) private ratesService!: RatesService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(HotelsServiceS) private hotelsService!: HotelsService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    @Prop({ type: Number })
    day!: Day;

    get documents() {
        if (!this.ratesService.data) {
            return null;
        }

        if (this.ratesService.data instanceof RatesDocumentAllModel) {
            return null;
        }

        const documents = {
            main: this.ratesService.data,
        } as Record<string, RatesDocumentModel | null>;

        return documents;
    }

    get compset() {
        return this.compsetsService.currentCompset;
    }

    get isDocumentLoading() {
        return this.ratesService.isLoading;
    }

    get hotelColors() {
        if (!this.compset) { return null; }
        return this.hotelsService.getHotelsGraphColor(this.compset.id);
    }

    get hotelColorsRgb() {
        if (!this.compset) { return null; }
        return this.hotelsService.getHotelsGraphColorRgb(this.compset.id);
    }

    get documentSettings() {
        return this.documentFiltersService.settings;
    }

    get ratesSettings() {
        return {
            main: this.ratesService.settings,
        };
    }
}
