var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.tableData)?_c('div',{class:{
        'table': true,
        'table--analysis': _vm.analysisKeys.length > 1,
        'skeleton': _vm.isLoading,
    }},[(!_vm.isCheapestChannel)?_c('p',{staticClass:"scanned-at"},[_vm._v(" "+_vm._s(_vm.$t('titles.scannedAt'))+" "+_vm._s(_vm.historyDate)+" ")]):_vm._e(),_c('div',{staticClass:"columns"},[(_vm.hasScreenshots)?_c('div',{staticClass:"table__column screenshots"},[_c('p',{staticClass:"title"}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'screenshot',class:{
                    'table__cell screenshot': true,
                    'table__cell--median': hotel.isCompset,
                }},[(hotel.screenshot)?_c('ScreenshotLink',{attrs:{"screenshot":hotel.screenshot}}):_vm._e()],1)})],2):_vm._e(),(!_vm.dayIndex && _vm.isPriceTypeLowest)?_c('div',{staticClass:"table__column price-type"},[_c('p',{staticClass:"title",domProps:{"textContent":_vm._s('Type')}}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'price-type',class:{
                    'table__cell': true,
                    'table__cell--median': hotel.isCompset,
                },domProps:{"textContent":_vm._s(!hotel.isCompset && hotel.priceType ? _vm.$tc(`price.${hotel.priceType}`) : '')}})})],2):_vm._e(),_c('div',{staticClass:"table__column name"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('titles.name')))]),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'name',class:{
                    'table__cell': true,
                    'table__cell--median' : hotel.isCompset,
                    'hotel-name': true,
                    'hotel-name--value': true,
                    'hotel-name--my': hotel.myHotel,
                    'hotel-name--hidden': _vm.hiddenGraphs[hotel.id],
                },on:{"click":function($event){return _vm.toggleGraph(hotel)}}},[_c('div',{class:{
                        'graph-legend': true,
                        'graph-legend--hidden': _vm.hiddenGraphs[hotel.id],
                        'graph-legend--transparent': hotel.isCompset,
                    },style:({
                        '--indicator-color': hotel.borderColor,
                    })}),_c(hotel.link ? 'a' : 'p',{tag:"component",class:{
                        'hotel-name__value': true,
                        'hotel-name__value--link': hotel.link,
                    },attrs:{"title":hotel.name,"href":hotel.link,"target":"_blank"},domProps:{"textContent":_vm._s(hotel.name)}}),(!!hotel.link)?_c('i',{staticClass:"icon-link"}):_vm._e()],1)})],2),_c('div',{staticClass:"table__column rank"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('titles.rank')))]),_vm._l((_vm.tableData),function(hotel){return _c('div',{key:hotel.id || hotel.name + 'rank',class:{
                    'table__cell rank__value': true,
                    'table__cell--median': hotel.isCompset,
                    'rank__value--my': hotel.myHotel,
                }},[_c('p',[_c('span',[_vm._v(_vm._s(hotel.rank || '---'))])])])})],2),_c('div',{staticClass:"table__column basic"},[_c('p',{staticClass:"title"}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'price',class:{
                    'table__cell basic__value': true,
                    'table__cell--median': hotel.isCompset,
                }},[(hotel.isBasic)?_c('img',{staticClass:"basic__icon",attrs:{"src":_vm.bookingBasicIcon}}):_vm._e()])})],2),_c('div',{staticClass:"table__column price"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.analysisKeys.length ? _vm.mainKey : _vm.$t('titles.price'))+" ")]),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'price',class:{
                    'table__cell price__value': true,
                    'table__cell--median': hotel.isCompset,
                    'price__value--my': hotel.myHotel,
                }},[(hotel.price && hotel.price !== -1)?_c('p',[_c('Currency',{attrs:{"currency":_vm.currency}}),_c('span',{attrs:{"title":hotel.isNetCalc ? _vm.$tc('rates.netRateMsg') : ''},domProps:{"textContent":_vm._s(_vm.PriceFilter(hotel.price) + (hotel.isNetCalc ? '*' : '') )}}),(hotel.myHotel && hotel.losRestriction)?_c('span',{domProps:{"textContent":_vm._s(`(LOS${hotel.losRestriction})`)}}):_vm._e(),(hotel.losRestriction && !hotel.myHotel)?[_c('span',{staticClass:"los-wrap"},[_c('span',{staticClass:"background"}),_c('i',{staticClass:"icon-los2"},[_c('span',[_vm._v(_vm._s(hotel.losRestriction))])])])]:_vm._e()],2):(hotel.price === 0)?_c('p',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('soldOut'))}})]):(hotel.price === -1)?_c('p',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('na'))}})]):_c('p',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('noData'))}})])])})],2),(_vm.hasIntraday)?_c('div',{staticClass:"table__column"},[_c('p',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$tc('titles.prevscan'))}}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'intraday',class:{
                    'table__cell': true,
                    'table__cell--median': hotel.isCompset,
                }},[(!_vm.isIntradayLoading && !!hotel.intradayValue)?_c('Intraday',{attrs:{"special":!!hotel.specialIntraday,"title":hotel.specialDateTitle,"isSamePrice":hotel.intradayValue === hotel.price}},[(!_vm.isLoading)?_c('Currency',{attrs:{"currency":_vm.currency}}):_vm._e(),_vm._v(" "+_vm._s(_vm.PriceFilter(hotel.intradayValue))+" ")],1):_c('span',{domProps:{"textContent":_vm._s('...')}})],1)})],2):_vm._e(),_vm._l((_vm.analysisKeys),function(analysisKey,docIndex){return _c('div',{key:docIndex + 'analysisKey',staticClass:"table__column price"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(analysisKey)+" ")]),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'price',class:{
                    'table__cell price__value': true,
                    'table__cell--median': hotel.isCompset,
                    'price__value--my': hotel.myHotel,
                }},[(_vm.hasAnalysisData(hotel, docIndex))?_c('p',[_c('Currency',{attrs:{"currency":_vm.currency}}),_c('span',{domProps:{"textContent":_vm._s(_vm.PriceFilter(hotel.analysisData[docIndex].price))}}),_vm._v("  "),(_vm.isMainRoomDifferent(hotel, docIndex) && _vm.isValidComparePrice(hotel, docIndex) && !hotel.isCompset)?_c('i',{staticClass:"icon-Icons_Info",attrs:{"title":_vm.$tc('rates.thisPriceForRoom', 0, [hotel.analysisData[docIndex].roomName])}}):_vm._e(),(hotel.myHotel && hotel.analysisData[docIndex].losRestriction)?_c('span',{domProps:{"textContent":_vm._s(`(LOS${hotel.analysisData[docIndex].losRestriction})`)}}):_vm._e(),(hotel.analysisData[docIndex].losRestriction && !hotel.myHotel)?_c('span',{staticClass:"los-wrap"},[_c('span',{staticClass:"background"}),_c('i',{staticClass:"icon-los2"},[_c('span',{domProps:{"textContent":_vm._s(hotel.analysisData[docIndex].losRestriction)}})])]):_vm._e()],1):(_vm.isAnalysisDataSoldOut(hotel, docIndex))?_c('p',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('soldOut'))}})]):(_vm.isAnalysisDataNa(hotel, docIndex))?_c('p',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('na'))}})]):_c('p',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('noData'))}})])])})],2)}),_c('div',{staticClass:"table__column room-type"},[_c('p',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$t('titles.roomName'))}}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'roomName',class:{
                    'table__cell room-type__value': true,
                    'room-type__value--my': hotel.myHotel,
                    'table__cell--median': hotel.isCompset,
                },attrs:{"title":hotel.roomName}},[_c('p',[_c('span',[_vm._v(_vm._s(hotel.isCompset ? '---' : hotel.roomName))])])])})],2),(_vm.isCheapestChannel)?_c('div',{staticClass:"table__column sources"},[_c('p',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$tc('titles.providers'))}}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'sources',class:{
                    'table__cell sources__value': true,
                    'table__cell--median': hotel.isCompset,
                },attrs:{"title":hotel.roomName}},[_c('ProviderCard',{attrs:{"provider":hotel.providers,"logoSize":24}})],1)})],2):_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }