
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import type Day from '@/modules/common/types/day.type';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';
import formatDate from '@/modules/common/filters/format-date.filter';
import RatesScanTooltip from '@/modules/rates/components/rates-scans-tooltip.vue';

@Component({
    components: {
        ScanBtnSmall,
        RatesScanTooltip,
    },
})
export default class RatesScanBtn extends Vue {
    @inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;

    @Prop({ type: Number, default: () => null })
    private day!: Day | null;

    @Prop({ type: Object, default: () => null })
    private data!: RatesDocumentModel | null;

    @Prop({ type: Object, default: () => null })
    private settings!: RatesSettingsModel | null;

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    @Prop({ type: Boolean, default: () => false })
    showScanDate!: boolean;

    @Prop({ type: Date })
    lastScan!: Date | undefined;

    @Prop({
        type: String,
        default: '',
    })
    compsetId!: string;

    tooltipPos: HTMLDivElement | null = null;

    get scanStatus() {
        if (!this.data) {
            return null;
        }

        return this.data.scan.status;
    }

    get isScanning() {
        return this.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get isDisabled() {
        if (this.disabled) {
            return true;
        }

        const notAbleToScan = this.day ? !this.ratesCommonService.isScanAvailable(this.settings, this.day) : false;
        const { scanLimitation } = this.ratesCommonService;

        const haveNoScans = scanLimitation && scanLimitation.remaining === 0;

        return notAbleToScan || haveNoScans;
    }

    async handleClick() {
        if (this.isScanning || this.isDisabled || (!this.data && !this.compsetId)) {
            return;
        }

        this.$emit('triggerScan', this.day);
    }

    get filterAll() {
        return this.ratesFiltersService.isProviderAll;
    }

    get formatScanDate() {
        const emptyDate = '--/--/--';

        if (this.lastScan) return formatDate(this.lastScan);

        if (!this.day || !this.data) {
            return emptyDate;
        }

        const doc = this.ratesCommonService.getUpdateDate(this.day, this.data);

        if (doc === null) {
            return emptyDate;
        }

        return formatDate(doc);
    }
}
