var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"filters"},[_c('div',{class:{
            'filters__item compset': true,
            'skeleton': _vm.skeleton,
        }},[_c('p',{staticClass:"compset__label"},[_vm._v(" "+_vm._s(_vm.$t('titles.compset'))+": ")]),_c('p',{staticClass:"compset__value"},[_vm._v(" "+_vm._s(_vm.settings.compSet)+" ")])]),_c('div',{class:{
            'filters__item price': true,
            'skeleton': _vm.skeleton,
        }},[_c('p',{staticClass:"compset__label"},[_vm._v(" "+_vm._s(_vm.$t('titles.price'))+": ")]),_c('p',{staticClass:"compset__value"},[_vm._v(" "+_vm._s(_vm.settings.priceType && _vm.$t('price.' + _vm.settings.priceType))+" ")])]),_c('div',{class:{
            'filters__item source': true,
            'skeleton': _vm.skeleton,
        }},[_c('p',{staticClass:"source__label"},[_vm._v(" "+_vm._s(_vm.$t('titles.provider'))+": ")]),_c('p',{staticClass:"source__value"},[_vm._v(" "+_vm._s(_vm.settings.provider && _vm.getProviderLabel(_vm.settings.provider))+" ")])]),_c('div',{class:{
            'filters__item los': true,
            'skeleton': _vm.skeleton,
        }},[_c('p',{staticClass:"los__label"},[_vm._v(" "+_vm._s(_vm.$t('titles.los'))+": ")]),_c('p',{staticClass:"los__value"},[_vm._v(" "+_vm._s(_vm.settings.los)+" ")])]),_c('div',{class:{
            'filters__item pos': true,
            'skeleton': _vm.skeleton,
        }},[_c('p',{staticClass:"los__label"},[_vm._v(" "+_vm._s(_vm.$t('titles.pos'))+": ")]),_c('p',{staticClass:"los__value"},[_vm._v(" "+_vm._s(_vm.settings.pos)+" ")])]),(_vm.settings.numberOfGuests)?_c('div',{class:{
            'filters__item nog': true,
            'skeleton': _vm.skeleton,
        }},[_c('p',{staticClass:"los__label"},[_vm._v(" "+_vm._s(_vm.$t('titles.numberOfGuests'))+": ")]),_c('p',{staticClass:"los__value"},[_vm._v(" "+_vm._s(_vm.settings.numberOfGuests)+" ")])]):_vm._e(),_c('div',{class:{
            'filters__item room-type': true,
            'skeleton': _vm.skeleton,
        }},[_c('p',{staticClass:"room-type__label"},[_vm._v(" "+_vm._s(_vm.$t('titles.roomType'))+": ")]),_c('p',{staticClass:"room-type__value",domProps:{"textContent":_vm._s(_vm.$tc(_vm.roomType))}})]),_c('div',{class:{
            'filters__item meal-type': true,
            'skeleton': _vm.skeleton,
        }},[_c('p',{staticClass:"meal-type__label"},[_vm._v(" "+_vm._s(_vm.$t('titles.mealType'))+": ")]),_c('p',{staticClass:"meal-type__value",domProps:{"textContent":_vm._s(_vm.$tc(_vm.mealType))}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }