
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { inject } from '@/inversify';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import _ from 'lodash';

import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesPriceHistoryService, { RatesPriceHistoryServiceS } from '@/modules/price-history/rates-price-history.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesPriceHistoryAllService, { RatesPriceHistoryAllServiceS } from '@/modules/price-history/rates-price-history-all.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS } from '@/modules/price-history/rates-price-history-common.service';

import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import CompsetModel from '@/modules/compsets/models/compset.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import JsonViewer from '@/modules/common/components/ui-kit/json-viewer.vue';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import type Day from '@/modules/common/types/day.type';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import type { DialogOptions } from '@/modules/common/components/modal-wrapper.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import CURRENT_HOTEL_GRAPH_COLOR_RGB from '@/modules/common/constants/current-hotel-graph-color-rgb.constant';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import PopupEvents from '@/modules/events/components/popup-events.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesPriceHistoryFilters from './rates-price-history-filters.vue';
import RatesPriceHistoryHeader from './rates-price-history-header.vue';
import RatesPriceHistoryTable from './rates-price-history-table.vue';
import RatesPriceHistoryTableAll from './rates-price-history-table-all.vue';
import RatesPriceHistoryTooltip from './rates-price-history-tooltip.vue';
import PriceHistoryStatistics from './rates-price-history-statistics.vue';

const ZERO_DAY = '-  0  -';

@Component({
    components: {
        CustomGraph,
        CompsetScale,
        CustomCheckbox,
        PopupEvents,
        ModalWrapper,
        LoaderWrapper,
        TogglePrices,
        JsonViewer,
        RatesPriceHistoryHeader,
        RatesPriceHistoryFilters,
        RatesPriceHistoryTable,
        RatesPriceHistoryTableAll,
        RatesPriceHistoryTooltip,
        PopupEventsContainer,
        PriceHistoryStatistics,
        RatesDayScanBtn,
    },
})
export default class CommonDayPopup extends Vue {
    /** Common day popup. Only for internal use in other popups, don't use it directly */

    // [TODO] enything not related to price history service
    // (rates settings, document settings, rates document, compsets, etc)
    // should be moved to hotel/cluster-rates/all-day.popup.vue components.
    // Remove any data loading in lifecircle hooks.
    // Only dynamic loading and props.
    // Remove all page specific code.

    @inject(UserServiceS) private userService!: UserService;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(UserSettingsS) private userSettingsService!: UserSettingsService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(RatesPriceHistoryServiceS) private ratesPriceHistoryService!: RatesPriceHistoryService;
    @inject(RatesPriceHistoryAllServiceS) private ratesPriceHistoryAll!: RatesPriceHistoryAllService;
    @inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @inject(RatesServiceS) private ratesService!: RatesService;
    @inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;

    @Prop({ type: Number, required: false })
    day!: Day;

    @Prop({ type: Object })
    modalOptions!: DialogOptions;

    @Prop({ type: Object })
    documents!: Record<string, RatesDocumentModel | RatesDocumentAllModel | null> | null;

    @Prop({ type: Object })
    compset!: CompsetModel;

    @Prop({ type: Object })
    documentSettings!: DocumentFiltersModel;

    @Prop({ type: Object })
    ratesSettings!: Record<string, RatesSettingsModel>;

    @Prop({ type: Object })
    hotelColors!: Record<string, string>;

    @Prop({ type: Object })
    hotelColorsRgb!: Record<string, string>;

    @Prop({ type: Boolean })
    isDocumentLoading!: boolean;

    @Prop({ type: Boolean, default: false })
    isNoChart!: boolean;

    @Prop({ type: Boolean, default: false })
    isCluster!: boolean;

    hiddenGraphs: { [key: string]: boolean } = {};
    showRawData = false;

    tooltipFocusElement: HTMLElement | null = null;
    tooltipDay: Day | null = null;

    // [TODO] remove
    // Use service and service's watcher to change settings on document change
    @Watch('documents')
    handleDocumentChange() {
        this.initServiceData();
    }

    mounted() {
        this.localPriceShown = this.documentSettings.priceShown;
        this.initServiceData();
    }

    get isNetTotalPricesSame() {
        if (!this.documents) return true;

        return this.ratesCommonService
            .isNetTotalPricesSame(this.day, this.documents.main as RatesDocumentModel);
    }

    get isSuperadmin() {
        return this.userService.isSuperadmin;
    }

    get isNoData() {
        const { isNoData } = this.ratesPriceHistoryCommonService;
        const isChartEmpty = this.chartData?.datasets
            ? this.chartData.datasets.every((dataset: any) => !dataset.data?.filter(Boolean).length)
            : true;

        return (isNoData || isChartEmpty) && !this.isTrendsLoading;
    }

    get isDaySoldOut() {
        const isSoldOut = this.ratesService.isSoldOut(this.day, this.compset.ownerHotelId, this.localPriceShown);

        if (this.isAnalysisPage) {
            const { comparisonValues } = this.ratesAnalysisFiltersService;

            return isSoldOut || comparisonValues
                .every((_, docIndex) => this.ratesAnalysisService.isSoldOut(this.day, this.compset.ownerHotelId, this.localPriceShown, docIndex));
        }

        return isSoldOut;
    }

    get isAnalysisPage() {
        return this.$route.path.includes('analysis');
    }

    get isAllChannelsMode() {
        return this.documents?.main?.providerName === 'all';
    }

    get isTrendsLoading() {
        if (this.isDocumentLoading) {
            return true;
        }

        if (!this.documents && !this.isDocumentLoading) {
            return false;
        }

        return this.ratesPriceHistoryCommonService.isTrendsLoading;
    }

    get compareLabel() {
        if (!this.isAnalysisPage) return '';

        const { filterComparisonName, comparisonValues } = this.ratesAnalysisFiltersService;
        const values = comparisonValues.map(value => value.name).join(', ');

        return `${this.$tc(filterComparisonName || '')} - ${values}`;
    }

    get tableDay() {
        return this.ratesPriceHistoryCommonService.dayIndex;
    }

    get choosenValues() {
        if (this.isAnalysisPage) return '';

        const { comparisonValues: values } = this.ratesAnalysisFiltersService;
        return values.map(e => e.name).join(', ');
    }

    get options() {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 4,
                    backgroundColor: 'white',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#9B9B9B',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#9B9B9B',
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                        callback: value => `${this.currencySymbol}${value}`,
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        } as ChartOptions;
    }

    get chartColors() {
        return this.userSettingsService.chartColors || [];
    }

    get chartData(): ChartData | null {
        if (this.isNoChart || !this.documents) {
            return null;
        }

        const hiddenHotels = Object.keys(this.hiddenGraphs);

        let items: (number | string)[] | null = null;

        if (this.isAllChannelsMode) {
            items = this.ratesPriceHistoryCommonService.providers;
        } else {
            items = this.ratesPriceHistoryCommonService.hotels;
        }

        if (!items) {
            return {
                labels: [this.$tc('noData')],
                datasets: [{}],
            };
        }

        const labels = this.ratesPriceHistoryCommonService.sortedDaysList.map((_, index) => {
            if (index === 0) {
                return `-  ${index}  -`;
            }

            return String(index).length === 1
                ? `-0${index}`
                : -index;
        }).reverse();

        if (this.isAllChannelsMode) {
            const datasets: ChartDataSets[] = (items as string[])
                .map((provider: string, index) => ({
                    data: this.ratesPriceHistoryAll
                        .getPriceHistoryProvidersData(provider, this.localPriceShown) || [],

                    borderDash: provider === 'average' ? [] : [8, 4],
                    borderColor: provider === 'average'
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.chartColors[index] || '#000000',

                    lineTension: 0,
                    borderJoinStyle: 'round',
                    hidden: hiddenHotels.includes(provider),
                }));

            return {
                // NOTE: There is a bug in chartJs
                labels: labels.length
                    ? labels
                    : [this.$tc('noData')],
                datasets: datasets.length
                    ? datasets
                    : [{}],
            };
        }

        const datasets = Object.keys(this.ratesPriceHistoryCommonService.documents).reduce((acc, documentKey) => ([
            ...acc,
            ...(items as number[])
                .map((hotelId: number) => {
                    let borderColor = hotelId === this.compset.ownerHotelId
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.hotelColors[hotelId];

                    if (documentKey !== 'main') {
                        borderColor = hotelId === this.compset.ownerHotelId
                            ? `rgba(${CURRENT_HOTEL_GRAPH_COLOR_RGB},0.5)`
                            : `rgba(${this.hotelColorsRgb[hotelId] || '0, 0, 0'},0.5)`;
                    }

                    return {
                        data: (this.ratesPriceHistoryCommonService
                            .getPricesTrend(hotelId, this.localPriceShown, documentKey) || []),
                        pointBorderWidth: 1,
                        borderWidth: hotelId === this.compset.ownerHotelId ? 3 : 2,
                        borderColor,
                        lineTension: 0,
                        borderJoinStyle: 'round',
                        hidden: hiddenHotels.includes(hotelId.toString()),
                    };
                }) as ChartDataSets[],
        ]), [] as ChartDataSets[]);

        return {
            labels,
            datasets,
        };
    }

    get comparisonValues() {
        if (this.isAnalysisPage) return [];

        return this.ratesAnalysisFiltersService.comparisonValues;
    }

    get currencySymbol(): string | null {
        const { currency } = this.ratesPriceHistoryService;

        return currency
            ? this.helperService.currencySymbol(currency)
            : null;
    }

    get localPriceShown() {
        if (!this.ratesPriceHistoryCommonService.localPriceShown) {
            this.ratesPriceHistoryCommonService.localPriceShown = this.documentSettings?.priceShown || PRICE_SHOWN.SHOWN;
        }
        return this.ratesPriceHistoryCommonService.localPriceShown;
    }

    set localPriceShown(p: PRICE_SHOWN) {
        this.ratesPriceHistoryCommonService.localPriceShown = p;
    }

    // [TODO] move this logic to service watchers
    initServiceData() {
        this.ratesPriceHistoryCommonService.setTableDay(0);

        // To show filters in header even if no data
        if (!this.documents) {
            this.ratesPriceHistoryCommonService.initRatesData(
                null,
                this.ratesSettings,
                this.documentSettings,
                this.compset,
                this.day,
                this.isDocumentLoading,
            );
            return;
        }

        const isSettingsSame = Object.keys(this.ratesSettings).length === Object.keys(this.ratesPriceHistoryCommonService.ratesSettings || {}).length
            && !Object.keys(this.ratesSettings).find(documentKey => {
                const settingsInStore = this.ratesPriceHistoryCommonService.ratesSettings
                    ? this.ratesPriceHistoryCommonService.ratesSettings[documentKey]
                    : null;
                const settingsInProps = this.ratesSettings
                    ? this.ratesSettings[documentKey]
                    : null;
                return _.isEqual(settingsInStore, settingsInProps);
            });

        const isDocumentsSame = Object.keys(this.documents).length === Object.keys(this.ratesPriceHistoryCommonService.rateDocuments || {}).length
            && !Object.keys(this.documents).find(documentKey => {
                const docuementInStore = this.ratesPriceHistoryCommonService.rateDocuments
                    ? this.ratesPriceHistoryCommonService.rateDocuments[documentKey]
                    : null;
                const documentInProps = this.documents
                    ? this.documents[documentKey]
                    : null;
                return docuementInStore?.id !== documentInProps?.id;
            });

        if (isDocumentsSame && isSettingsSame && this.ratesPriceHistoryCommonService.docDay === this.day) {
            return;
        }

        this.ratesPriceHistoryCommonService.initRatesData(
            this.documents,
            this.ratesSettings,
            this.documentSettings,
            this.compset,
            this.day,
            this.isDocumentLoading,
        );
    }

    toggleGraph(hotel: { id: string }) {
        if (this.hiddenGraphs[hotel.id]) {
            this.$delete(this.hiddenGraphs, hotel.id);
            return;
        }

        this.$set(this.hiddenGraphs, hotel.id, true);
    }

    setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }

    setTooltipDay(label: string) {
        if (label === ZERO_DAY) {
            this.tooltipDay = 0 as Day;
            return;
        }

        this.tooltipDay = (label
            ? parseInt(label, 10)
            : null) as Day;
    }

    setDay(label: string) {
        const dayIndex = parseInt(label.replace('-', '').trim(), 10);
        this.ratesPriceHistoryCommonService.setTableDay(dayIndex);
    }
}
